import EXIF from "exif-js/exif";
import axios from "axios";
import $ from "./utils";
axios.defaults.withCredentials = true; // 若跨域请求需要带 cookie 身份识别

export function getFileURL (file) {
  let promise = new Promise(resolve => {
    if (URL.createObjectURL) {
      resolve(URL.createObjectURL(file));
      return;
    }

    if (window.webkitURL) {
      resolve(window.webkitURL.createObjectURL(file));
      return;
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      resolve(e.target.result);
    };
  });

  return promise;
}

export function dataURLtoFile (dataurl, filename) {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function compressImage (file) {
  let promise = new Promise((resolve, reject) => {
    getFileURL(file).then(dataURL => {
      const img = new Image();
      img.src = dataURL;
      img.onerror = error => reject(error);
      img.onload = () => {
        // 计算缩放因子
        // 这里我取水平和垂直方向缩放因子较大的作为缩放因子，这样可以保证图片内容全部可见
        const scaleX =
          img.width /
          (document.documentElement.clientWidth *
            window.devicePixelRatio);
        const scaleY =
          img.height /
          (document.documentElement.clientHeight *
            window.devicePixelRatio);
        let scale = Math.max(scaleX, scaleY);

        let imageWidth = parseInt(img.width / scale);
        let imageHeight = parseInt(img.height / scale);
        imageWidth = img.width;
        imageHeight = img.height;

        // 将原始图片按缩放因子缩放后，绘制到画布上
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = imageWidth;
        canvas.height = imageHeight;
        ctx.drawImage(img, 0, 0, imageWidth, imageHeight);
        console.log(file);
        let size = file.size / 1024 / 1024;
        let quality = 0.6;
        if (size >= 1 && size < 2) {
          quality = 0.8;
        } else if (size >= 2 && size < 3) {
          quality = 0.7;
        } else if (size >= 3 && size < 4) {
          quality = 0.6;
        } else if (size >= 4 && size < 5) {
          quality = 0.5;
        } else if (size >= 5 && size < 4) {
          quality = 0.4;
        } else if (size >= 6 && size < 3) {
          quality = 0.3;
        } else if (size >= 7 && size < 2) {
          quality = 0.2;
        } else if (size >= 8) {
          quality = 0.1;
        }
        // const quality = 0.1; // 指定图片 MIME 类型为 'image/jpeg', 通过 quality 控制导出的图片质量，进行实现图片的压缩
        let dataURL = canvas.toDataURL("image/jpeg", quality);
        resolve(dataURLtoFile(dataURL, file.name)); // 返回新图片
      };
    });
  });

  return promise;
}

export function getImageInfo (file) {
  let promise = new Promise((resolve, reject) => {
    getFileURL(file).then(dataURL => {
      const img = new Image();
      img.src = dataURL;
      img.onerror = error => reject(error);
      img.onload = () => {
        let data = {
          data: dataURL,
          width: img.width,
          height: img.height,
          status: "上传中"
        };
        resolve(data);
      };
    });
  });

  return promise;
}

//上传图片
export const uploadFile = async (file, token) => {
  if (file.size > 1 * 1024 * 1024) {
    file = await compressImage(file);
  }
  let fd = new FormData();
  fd.append("file", file);

  return new Promise((resolve, reject) => {
    const headers = {
      "Cache-Control": "no-cache",
      "Content-Type": "application/json",
      "x-requested-with": "XMLHttpRequest",
      token
    };
    let uploadurl = "";
    if ($.dev) {
      //开发环境代理
      uploadurl = $.config.api + "common/upload";
    } else {
      uploadurl = $.config.api + "common/upload";
    }
    console.log($.config.api + "common/upload");
    axios({
      method: "POST",
      url: uploadurl,
      data: fd,
      headers
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(error => {
        reject(error);
      });
  });
};

//
export function runTarget (img) {
  // 图片原始尺寸
  let originWidth = img.width;
  let originHeight = img.height;
  // 最大尺寸限制
  let maxWidth = 1920,
    maxHeight = 1920;
  // 目标尺寸
  let targetWidth = originWidth,
    targetHeight = originHeight;
  // 图片尺寸超过400x400的限制
  if (originWidth > maxWidth || originHeight > maxHeight) {
    if (originWidth / originHeight > maxWidth / maxHeight) {
      // 更宽，按照宽度限定尺寸
      targetWidth = maxWidth;
      targetHeight = Math.round(maxWidth * (originHeight / originWidth));
    } else {
      targetHeight = maxHeight;
      targetWidth = Math.round(maxHeight * (originWidth / originHeight));
    }
  }
  return {
    targetWidth: targetWidth,
    targetHeight: targetHeight
  };
}

// ios拍照获取图片旋转问题
export function fixImageOrientation (file) {
  let promise = new Promise(resolve => {
    getFileURL(file).then(dataURL => {
      console.log(file)
      const img = new Image();
      img.src = dataURL;
      img.onerror = () => resolve(file);
      img.onload = () => {
        EXIF.getData(img, function () {
          let orientation = EXIF.getTag(this, "Orientation"); // 获取图片旋转标志位
          // alert(orientation)
          // 3 需要180度旋转
          // 6 需要顺时针（向左）90度旋转
          // 9 需要逆时针（向右）90度旋转   
          // 1 正常，不需要旋转
          if (
            orientation === 3 ||
            orientation === 6 ||
            orientation === 8
          ) {
            // 根据旋转角度，在画布上对图片进行旋转
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            let { targetWidth, targetHeight } = runTarget(img);
            switch (orientation) {
              case 3: // 旋转180°
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                ctx.rotate((180 * Math.PI) / 180);

                ctx.fillStyle = "#fff";
                ctx.drawImage(
                  img,
                  -targetWidth,
                  -targetHeight,
                  targetWidth,
                  targetHeight
                );
                break;
              case 6: // 旋转90°

                canvas.width = targetHeight;
                canvas.height = targetWidth;
                ctx.rotate((Math.PI) / 180);
                ctx.drawImage(
                  img,
                  0,
                  targetWidth,
                  targetHeight,
                  -targetWidth
                );
                break;

              case 8: // 旋转-90°
                canvas.width = targetHeight;
                canvas.height = targetWidth;
                ctx.fillStyle = "#fff";
                ctx.rotate((-90 * Math.PI) / 180);
                ctx.drawImage(
                  img,
                  -targetWidth,
                  0,
                  targetWidth,
                  targetHeight
                );
                break;
            }

            const quality = 1;
            let dataURL = canvas.toDataURL("image/jpeg", quality);
            resolve(dataURLtoFile(dataURL, file.name)); // 返回新图片
          } else {
            return resolve(file);
          }
        });
      };
    });
  });

  return promise;
}
