<template>
  <div class="image-upload">
    <van-uploader v-model="fileList" :max-size="maxSize" :preview-size="previewSize" :after-read="afterRead" @oversize="oversize">
      <div class="upload-area">
        <!-- <van-icon name="plus"
                  size="16px"
                  color="#CEB894"></van-icon> -->
        <img src="@/static/img/photo-icon.svg" width="24" alt="">

      </div>
      <slot></slot>
    </van-uploader>

  </div>
</template>

<script>
import Vue from 'vue'
import { Uploader, Icon } from 'vant'
Vue.use(Uploader)
Vue.use(Icon)
import $ from '@p/utils'
import Compressor from 'compressorjs'
import { uploadFile, fixImageOrientation } from '../../public/myImage'
console.log(fixImageOrientation)
export default {
  name: 'image-upload',
  model: {
    event: 'change',
  },
  props: {
    value: {
      default() {
        return []
      },
    },
    // 是否开启自动压缩
    autoCompress: {
      default: true,
      type: Boolean,
    },
    maxCount: {
      default: 9,
      type: [Number, String],
    },
    action: {
      default: 'patient-api/common/upload',
    },
    maxSize: {
      default: 1024 * 1024 * 10,
      type: [Number, String],
    },
    // accept: {
    //     default: "image/gif,image/jpeg,image/jpg,image/png"
    // },
    previewSize: {
      default: '70px',
      type: [Number, String],
    },
    previewImage: {
      default: true,
      type: Boolean,
    },
    // multiple: {
    //     default: true,
    //     type: Boolean
    // }
  },
  data() {
    return {
      fileList: this.value,
    }
  },
  methods: {
    oversize() {
      this.$toast(`文件大小超过限制`)
    },
    compressor(file) {
      console.log(file)
      let size = file.size / 1024 / 1024
      let quality = 0.6
      if (size >= 1 && size < 2) {
        quality = 0.8
      } else if (size >= 2 && size < 3) {
        quality = 0.7
      } else if (size >= 3 && size < 4) {
        quality = 0.6
      } else if (size >= 4 && size < 5) {
        quality = 0.5
      } else if (size >= 5 && size < 4) {
        quality = 0.4
      } else if (size >= 6 && size < 3) {
        quality = 0.3
      } else if (size >= 7 && size < 2) {
        quality = 0.2
      } else if (size >= 8) {
        quality = 0.1
      }
      if (file && file.type.substr(0, 6) === 'image/' && this.autoCompress) {
        let promise = new Promise((resolve, reject) => {
          new Compressor(file, {
            quality: quality,
            success(newfile) {
              console.log(newfile)
              let dataFile = new File([newfile], newfile.name)
              console.log(dataFile)
              resolve(dataFile)
            },
            error(err) {
              reject(err)
            },
          })
        })
        return promise
      }
    },
    afterRead(file) {
      this.$emit('after')
      console.log(file)
      console.log(this.fileList)
      this.fileList.map(async (item) => {
        if (item.status) {
          return
        }
        // if (this.autoCompress) {
        //     item.status = 'uploading'
        //     item.message = '压缩中'
        //     this.$emit('change', this.fileList)
        //     this.compressor(item.file).then(res => {
        //         console.log('=======>',res)
        //         this.upload(item, res)
        //     })
        // } else {
        //     this.upload(item)
        // }
        item.status = 'uploading'
        item.message = '压缩中'
        const newFile = await fixImageOrientation(item.file)
        console.log(newFile, item.file)
        item.message = '上传中'
        item.status = 'uploading'
        uploadFile(newFile, localStorage.getItem('loginToken'))
          .then((res) => {
            console.log(res)
            if (
              $.refreshTokenCode.includes(res.code) ||
              $.needLoginCode.includes(res.code)
            ) {
              // 未登录和无用户
              if ($._self.$route.name != 'login') {
                $.goLogin('')
              }
            } else if (res.msg && res.code === 0) {
              item.url = res.msg
              item.status = 'done'
              console.log(file)
              this.$emit('change', this.fileList)
            }
          })
          .catch((error) => {
            item.status = 'failed'
            item.message = '上传失败'
            this.$emit('change', this.fileList)
          })
      })
    },
    upload(file, compressFile) {
      let param = this.autoCompress ? compressFile : file.file
      console.log(file, compressFile, param)
      file.message = '上传中'
      file.status = 'uploading'
      this.$axios({
        type: 'POST',
        url: this.action,
        data: {
          file: param,
        },
        elseData: {
          uploadData: true,
        },
        _self: this,
      })
        .then((res) => {
          console.log(res)
          file.url = res.msg
          file.status = 'done'
          console.log(file)
          this.$emit('change', this.fileList)
        })
        .catch((error) => {
          file.status = 'failed'
          file.message = '上传失败'
          this.$emit('change', this.fileList)
        })
    },
  },
}
</script>

<style lang="scss">
.image-upload {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 30px;

  .upload-area {
    width: 70px;
    height: 70px;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
  }

  .van-uploader__preview-image {
    border-radius: 2px !important;
  }
  .van-uploader__preview {
    margin: 0 10px 10px 0;
    display: none;
  }

  .van-uploader__preview-delete {
    top: -7px;
    right: -7px;
    font-size: 16px;
    width: 16px;
    height: 16px;
    background: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .van-icon {
      position: unset;
    }
  }
}
</style>
