<!--
 * @Author: 张博洋
 * @Date: 2021-08-30 18:10:07
 * @LastEditTime: 2022-03-10 12:10:05
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/views/im/index.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div @click="handleClick">
    <WaitingPage :state="state" :orderType="orderType" :serviceOrderEndTime="serviceOrderEndTime" :payexpiretime="payexpiretime" @notifyFinsh="notifyFinsh"></WaitingPage>
    <div class="im-list" id="imListRefresh" ref="imListRefresh">
      <app-refresh ref="downRefresh" class="im-refresh" :refresh-num='200' :refresh="canPull" @downRefresh="onRefresh" :ajax-time='0'>
        <ImList :list="list" :state="state" :docAvatar="docAvatar"></ImList>
      </app-refresh>
    </div>
    <div class="send-area" v-if="sendAreaShow || (orderType == 1 && state == 2 ) || (orderType == 2 && state == 3)">
      <!-- 语音icon -->
      <div class="icon mr8" @click="transformVoiceText" v-if="sendInputShow">
        <img src="../../static/img/voice-icon.svg" alt="">
      </div>
      <!-- 输入icon -->
      <div class="icon mr8" @click="transformVoiceText" v-if="!sendInputShow">
        <img src="../../static/img/keyboard-icon.svg" alt="">
      </div>
      <!-- 输入框 -->
      <van-field class="chat-input mr8" v-model="value" placeholder="输入你想回复医生的话" v-if="sendInputShow" />
      <!-- 发送语音按钮 -->
      <van-button class="chat-input mr8" v-if="!sendInputShow" @touchstart="touchstart">按住 说话</van-button>
      <!-- 表情icon -->
      <div class="icon mr8">
        <img src="../../static/img/emoji-icon.svg" alt="">
      </div>
      <!-- ➕ -->
      <div class="icon" @click="openPop" v-if="addIconShow">
        <img id="add-icon" src="../../static/img/add-icon.svg" alt="">
      </div>
      <!-- 发送按钮 -->
      <div class="send" @click="send" :style="{display:!addIconShow ? 'block':'none'}">发送</div>
    </div>
    <!-- 支付按钮 -->
    <div class="pay" v-if="state == '1'">
      <van-button color="#FF8200" class="btn-pay" @click="pay" round>支付</van-button>
    </div>

    <div class="addDiv" id="addDiv" :style="{height:popShow ? '260px':'0px'} ">

      <div class="icon">
        <image-upload v-model="fileList" :previewImage="false" class="image-upload" ref="upload" action="/patient-api/patient/common/upload" @after="handlerBeforeUpload" @change="handleUpload">
          照片
        </image-upload>

      </div>

    </div>
  </div>
</template>

<script>
import WaitingPage from './components/waiting.vue'
import ImList from './components/imList.vue'
import YbIm from '../../public/yb-im-sdk/src/index'
// import YbIm from 'ybcloud-im-sdk'
import imageUpload from '@/components/upload/imageUploadOne'
import $ from 'jquery'
import bus from '@/public/bus'
import button from '@/mixin/Button.js'
import { touchstart } from './touch.js'
export default {
  components: {
    WaitingPage,
    ImList,
    imageUpload,
  },
  data() {
    return {
      im: null,
      roomId: '',
      list: [],
      pageNo: 1,
      pageSize: 10,
      voiceIcon: '../../static/img/voice-icon.svg',
      keyboardIcon: '../../static/img/keyboard-icon.svg',
      value: '',
      popShow: false,
      addShow: true,
      addIconShow: true,
      payload: {},
      fileList: [],
      docAvatar: '',
      sendAreaShow: false,
      state: '',
      orderType: '',
      orderid: '',
      serviceOrderEndTime: 0,
      isLoading: false,
      canPull: true,
      timeValue: 5 * 60 * 1000,
      payexpiretime: 0,
      sendInputShow: true,
    }
  },
  mixins: [button],
  watch: {
    value: {
      handler(val) {
        console.log(val)
        if (!val.replace(/\s*/g, '')) {
          this.addIconShow = true
        } else {
          this.addIconShow = false
        }
      },
    },
  },
  created() {
    console.log('历史历史历史历史历史历史历史历史历史', this.$route.query)
    if (this.$route.query.orderType == 2) {
      this.getTimeAndState()
    }
    if (this.$route.query.orderType == 1 && this.$route.query.state == 2) {
      this.sendAreaShow = true
    }
    this.getDoctorBaseInfo()
    this.roomId = this.$route.query.roomId
    this.state = this.$route.query.state
    this.orderType = this.$route.query.orderType
    this.payexpiretime = this.$route.query.payexpiretime
    localStorage.setItem('state', this.$route.query.state)
    // 初始化 SDK 实例
    try {
      this.im = new YbIm({ autoSocket: false, env: 'prod' }) //autoSocket：进入聊天室，socket是否自动连接，非自动连接，需要在进入房间成功后调用connectSocket接口，env: dev, test or prod ,默认是prod
      console.log(this.im)
      this.connect()
      this.pageNo = 1
      // 拉完消息
      this.getMsg().then(() => {
        // 等待图片加载完
        let n = this.$refs.imListRefresh.querySelectorAll('img').length
        this.$refs.imListRefresh.querySelectorAll('img').forEach((item) => {
          item.onload = () => {
            n--
            console.log(n)
            if (n === 0) {
              this.goMessageBottom()
            }
          }
        })
        // this.goMessageBottom()
      })
    } catch (e) {
      console.log(e)
    }
  },
  mounted() {},
  activated() {
    if (this.$route.query.orderType === 2) {
      this.getTimeAndState()
    }
    if (this.$route.query.orderType === 1 && this.$route.query.state) {
      this.sendAreaShow = true
    }
    this.getDoctorBaseInfo()
    this.goMessageBottom()
  },
  destroyed() {
    this.resetPatientUnReadCount()
    this.im.exitRoom()
  },

  methods: {
    touchstart,
    getTimeAndState() {
      this.$axios({
        url: 'order/getOrderStateAndTime',
        data: {
          serviceOrderId: this.$route.query.orderid,
        },
        elseData: {
          ajaxLoading: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        this.serviceOrderEndTime = res.d.serviceOrderEndTime
        this.state = res.d.state
      })
    },
    // 处理消息
    handleMessage(data) {
      console.log(data)

      // 如果messageType === 9 的时候，撤回消息
      if (JSON.parse(data.text).messageType === 9) {
        let recallMsgId = JSON.parse(data.text).recallMsgId
        this.list = this.list.filter((item) => {
          return item.messageId !== recallMsgId
        })

      }

      if (JSON.parse(data.text).messageType === 33) {
        this.getTimeAndState()
        this.sendAreaShow = true
        this.state = 3
      }
      if (JSON.parse(data.text).messageType === 999) {
        // alert()
        this.sendAreaShow = false
        this.state = JSON.parse(data.text).state
      }

      if (JSON.parse(data.text).messageType === 1002) {
        // alert()
        this.sendAreaShow = true
        this.state = JSON.parse(data.text).state
      }

      if (JSON.parse(data.text).messageType === 1003) {
        console.log('==================>>>>>>>>>>',data)
        this.sendAreaShow = false
        this.state = JSON.parse(data.text).state
      }

      let message = {}
      // 如果不是患者发的，推到list里
      if (JSON.parse(data.text).from !== 100  ||  (JSON.parse(data.text).from === 100 && JSON.parse(data.text).messageType === 1003)) {

        // console.log('=======', this.list)
        if (
          JSON.parse(data.text).sendTime -
            this.list[this.list.length - 1].sendTime >
          this.timeValue
        ) {
          Object.assign(
            message,
            JSON.parse(data.text),
            { messageId: data.id },
            { expend: true }
          )
        } else {
          Object.assign(message, JSON.parse(data.text), { messageId: data.id })
        }
        console.log('=======', message)
        this.list = [...this.list, message]
      } else {
        console.log(this.list)
        bus.$emit('message', false)
      }

      // 过滤不是给患者的消息
      this.list = this.list.filter((item) => {
        return (
          item.to.indexOf(100) !== -1 ||
          (item.to.indexOf(0) !== -1 && item.messageType !== 9)
        )
      })
      setTimeout(() => {
        this.goMessageBottom()
      }, 0)
    },
    // 处理没法图片之前时
    handlerBeforeUpload() {
      console.log('===========')
      let cloneObj = {}
      this.payload.url = ''
      this.payload.messageType = 2
      this.payload.from = 100
      this.payload.to = [0]
      console.log('======================', this.payload)
      // Object.assign(cloneObj, this.payload, { loadingShow: true })
      this.list.push(
        Object.assign(cloneObj, this.payload, { loadingShow: true })
      )
      setTimeout(() => {
        this.goMessageBottom()
      }, 0)
    },
    connect() {
      console.log(this.im)
      this.im
        .enterRoom({
          roomID: this.roomId,
          avatar: '',
          pwd: `room-${this.roomId}`,
        })
        .then((data) => {
          console.log('join room, get token')
          this.im.connectSocket(this.roomId)
          // 收到文本消息回调
          this.im.on(YbIm.EVENT.TEXT_MESSAGE_RECEIVED, (data) => {
            this.handleMessage(data)
          })
          // 收到图片消息回调
          this.im.on(YbIm.EVENT.IMG_MESSAGE_RECEIVED, (data) => {
            console.log(data)
          })
          // 收到用户进出房间提示消息回调
          this.im.on(YbIm.EVENT.USER_TIPS, (data) => {
            console.log(data)
          })
          //收到socket连接失败回调
          this.im.on(YbIm.EVENT.SOCKET_CONNECT_FAIL, (data) => {
            console.log(data)
          })
          //收到管理员群发消息回调
          this.im.on(YbIm.EVENT.MANAGER_GROUP_SEND_MESSAGE_RECEIVED, (data) => {
            console.log(data)
            this.handleMessage(data)
          })
          //踢出房间回调
          this.im.on(YbIm.EVENT.KICKED_OUT_RECEIVED, () => {
            console.log('踢出房间')
          })
          //用户加入房间回调
          this.im.on(YbIm.EVENT.USER_JOIN, (data) => {
            console.log(data)
          })
        })
        .catch((error) => {
          console.log('join room fail')
          console.log(error)
          // alert(error)
          if (error.code === 10003) {
            console.log('直播间不存在')
          }
        })
    },
    async getMsg() {
      return new Promise(async (resolve) => {
        this.$axios({
          url: 'v1/chat/history',
          data: {
            pageNo: this.pageNo,
            pageSize: this.pageSize,
            roomId: this.roomId,
          },
          elseData: {
            // loading: true,
            ajaxLoading: true,
          },
          _self: this,
        }).then((res) => {
          if (res.d.data.length < this.pageSize) {
            this.isLoading = false
            this.canPull = false
          }
          // console.log('历史列表', [...res.d.data.reverse()])
          // let newArr = res.d.data.reverse()

          let arr = res.d.data.reverse()
          console.log(arr)
          console.log(res.d.data)

          this.list = [...arr, ...this.list]

          var obj = {}
          this.list = this.list.reduce(function (item, next) {
            obj[next.messageId]
              ? ''
              : (obj[next.messageId] = true && item.push(next))
            return item
          }, [])

          this.list.filter((item) => {
            return item.to.indexOf(100) !== -1 || item.to.indexOf(0) !== -1
          })

          console.log(this.list)
          // let temp = this.list[0].sendTime
          for (let i = 0; i < this.list.length; i++) {
            if (i === 0) {
              this.list[i].expend = true
            } else {
              if (
                this.list[i].sendTime - this.list[i - 1].sendTime >
                this.timeValue
              ) {
                this.list[i].expend = true
              }
            }
          }
          console.log('拉完的全部历史', this.list)
          this.isLoading = false
          resolve(this.list)
        })
      })
    },
    //回到消息底部
    goMessageBottom() {
      this.$nextTick(() => {
        let scrollDom = document.querySelector('#imListRefresh')
        let nowDom =
          document.querySelectorAll('.message-box')[this.list.length - 1]
        console.log(this.$.getPositiont(nowDom).top)
        $('#imListRefresh').animate(
          {
            scrollTop:
              this.$.getPositiont(nowDom).top -
              scrollDom.clientHeight +
              nowDom.clientHeight +
              10,
          },
          400
        )
      })
    },
    // 点击加号打开隐藏弹窗
    openPop() {
      console.log('1111')
      this.popShow = true
    },
    // 处理点击
    handleClick(e) {
      console.log(e)
      if (
        e.target.id !== 'addDiv' &&
        e.target.id !== 'add-icon' &&
        e.target.className !== 'van-uploader__input' &&
        e.target.className !== 'image-upload'
      ) {
        if (this.popShow === true) this.popShow = false
      }
    },
    // 发送文字消息
    send() {
      this.payload.text = this.value
      this.payload.messageType = 1
      this.payload.from = 100
      this.payload.to = [0]
      let cloneObj = {}
      let tempArr = [...this.list].reverse()
      let tempObj = tempArr.find((item) => {
        return item.expend
      })

      console.log('==========', tempObj)

      if (+new Date() - tempObj.sendTime > this.timeValue) {
        this.list.push(
          Object.assign(
            cloneObj,
            this.payload,
            { loadingShow: true },
            { expend: true }
          )
        )
      } else {
        this.list.push(
          Object.assign(cloneObj, this.payload, { loadingShow: true })
        )
      }
      this.goMessageBottom()
      this.im
        .sendTextMessage(this.roomId, {
          type: 1, // 1是文字，2是图片  Number类型
          payload: JSON.stringify(this.payload), //文本内容   String类型
        })
        .then(() => {
          this.value = ''
          bus.$emit('message', false)
        })
        .catch((error) => {
          if (error.code === 10005) {
            this.$store.commit('showMessage', {
              message: error.msg,
              type: 'error',
            })
          } else if (error.code === 10003) {
            console.log('发送太频繁，请稍后再试')
          }
        })
    },

    // 上传
    handleUpload(list) {
      console.log(list)
      this.payload.url = list[list.length - 1].url
      this.$set(
        this.list[this.list.length - 1],
        'url',
        list[list.length - 1].url
      )
      bus.$emit('message', false)
      this.im
        .sendTextMessage(this.roomId, {
          type: 1, // 1是文字，2是图片  Number类型
          payload: JSON.stringify(this.payload), //文本内容   String类型
        })
        .then(() => {
          // this.list.push(this.payload)
          this.fileList = []
          this.payload = {}
          // this.list.splice(this.list.length - 1, 1)
        })
        .catch((error) => {
          if (error.code === 10005) {
            this.$store.commit('showMessage', {
              message: error.msg,
              type: 'error',
            })
          } else if (error.code === 10003) {
            console.log('发送太频繁，请稍后再试')
          }
        })
    },
    // 获取医生基本信息
    getDoctorBaseInfo() {
      this.$axios({
        url: 'doctor/getBaseDoctorById',
        data: {
          id: this.$route.query.doctorAccountId,
        },
        elseData: {
          ajaxLoading: true,
        },
      }).then((res) => {
        this.docAvatar = res.d.avatar
        this.$.setTitle(res.d.doctorName + ' ' + res.d.technicalName)
      })
    },
    notifyFinsh(payload) {
      console.log(payload)
      this.sendAreaShow = payload
    },
    notifyPayEnd() {
      this.state = '5'
    },
    onRefresh() {
      if (!this.canPull) {
        this.isLoading = false
        this.$refs.downRefresh.downRefreshOver(true) //执行加载完成方法！
        return
      }
      this.pageNo++
      this.getMsg().then(() => {
        this.$refs.downRefresh.downRefreshOver(true) //执行加载完成方法！
      })
    },
    pay() {
      wx.miniProgram.redirectTo({
        url: `/pages/pay/pay?id=${this.$route.query.orderid}&payType=0`,
      })
      // alert(id)
    },
    // 重置红点
    resetPatientUnReadCount() {
      this.$axios({
        url: 'message/resetPatientUnReadCount',
        type: 'post',
        data: {
          roomId: this.roomId,
        },
      }).then((res) => {})
    },
    transformVoiceText() {
      this.sendInputShow = !this.sendInputShow
    },
    record() {
      console.log('111')
      wx.startRecord({
        success: function (res) {
          console.log(res)
          var tempFilePath = res.tempFilePath
        },
        fail: function (res) {
          //录音失败
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.im-list {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  background-color: #eee;
  .im-refresh {
    position: relative;
  }
}
.pay {
  background: #fff;
  padding: 10px 16px 30px 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .btn-pay {
    width: 100%;
  }
}
.send-area {
  background: #fff;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding-bottom: calc(10px + constant(safe-area-inset-bottom));
  padding-bottom: calc(10px + env(safe-area-inset-bottom));
  .icon {
    width: 24px;
    height: 24px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  .chat-input {
    height: 40px;
    background: #f5f5f5;
    border-radius: 20px;
    font-size: 14px;
    flex: 1;
    /deep/.van-field__body {
      height: 100%;
    }
  }
  .send {
    width: 60px;
    height: 30px;
    background: #1ab370;
    text-align: center;
    line-height: 30px;
    color: #fff;
    font-size: 12px;
    border-radius: 16px;
    transition: width 1s;
    // transition: display 1s;
  }
}
.addDiv {
  height: 260px;
  background: #f7f7f7;
  display: flex;
  transition: height 0.2s;
  & > div {
    position: relative;
    margin: 40px 0 0 30px;
    height: fit-content;
  }

  .image-upload {
    width: 100%;
    height: 100%;

    /deep/ .van-uploader__input-wrapper {
      // position: absolute;
      // left: 0;
      // top: 0;
    }
    /deep/.van-uploader {
      width: 100%;
      height: 100%;
    }
  }
}
.h0 {
  height: 0px !important;
  transition: height 0.2s;
}
</style>
