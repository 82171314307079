/*
 * @Author: 张博洋
 * @Date: 2022-01-16 00:13:51
 * @LastEditTime: 2022-01-16 00:18:54
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /h5/src/views/im/touch.js
 * 可以输入预定的版权声明、个性签名、空行等
 */
export function touchstart(e) {
  console.log(e.target)
}
